.App {
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background:radial-gradient(circle, rgba(0,172,217,1) 38%, rgba(1,174,59,1) 100%)

}


